import request, {
  SERVICE_MODULE_MAP
} from "@/utils/request";


export class StockGroupApi {


  //获取仓库list 
  getLabelList(params) {
    return request({
      baseURL: SERVICE_MODULE_MAP.supportModule,
      url: '/settings/labels',
      method: 'get',
      params,
    });
  }

  //删除仓库list 
  deleteWarehouse(data) {
    return request({
      baseURL: SERVICE_MODULE_MAP.supportModule,
      url: '/settings/labels/delete',
      method: 'post',
      data,
    });
  }

  //新增 仓库list 
  insertWarehouse(data) {
    return request({
      baseURL: SERVICE_MODULE_MAP.supportModule,
      url: '/settings/labels',
      method: 'post',
      data,
    });
  }

  //编辑修改仓库list
  updateWarehouse(data) {
    return request({
      baseURL: SERVICE_MODULE_MAP.supportModule,
      url: '/settings/labels/' + data.id,
      method: 'post',
      data,
    });
  }
}